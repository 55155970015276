.container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:12%;
    margin: 10%;
}

.para{
    display: flex;
    width: 60%;
}

.head{
    position: relative;
    left:40%;
}

.footer{
    position: absolute;
    top:240%;
    width:100%;
}

@media only screen and (max-width: 600px){
    .footer{
        top: 331%;
    }
    .head{
        left: 18%;
    }
}