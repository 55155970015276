.Home{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
   
}
.body{
    width: 100%;
    position: absolute;
    top: 20%;
}
.footer{
    position:absolute;
    width: 100%;
    bottom: -425%;
}
/* .carousel{
    width: 50%;
    position: absolute;
    left:50%;
} */

@media only screen and (max-width: 600px){
    .footer{
        bottom: -584%;
    }
}