.img{
    width: 50%;
    height: 100%;
}

.imgsize{
    width: 200%;
    height: 590px;
}

.carousel{
    width: 100%;
    height: 50%;
    border-radius:5px;
    opacity: 1;
}

.head{
    color: white;
    margin: 0;
    width: 70%;
    z-index: 700;
    font-size: 570%;
    position: absolute;
    left: 25%;
    font-family: "Trirong", serif;
}

.para{
    color: white;
    margin: 0;
    width: 70%;
    z-index: 700;
    font-size: 200%;
    position: absolute;
    left: 20%;
    top: 4%;
    font-family: "Trirong", serif;
}

@media only screen and (max-width: 600px){
    .head{
        color: white;
        margin: 0;
        width: 70%;
        z-index: 700;
        font-size: 400%;
        position: absolute;
        left: 6%;
        font-family: "Trirong", serif;
    }
    
}