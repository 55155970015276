
.span{
    color: red;
    position: relative;
}

.head{
  position: relative;
  left:40%;
  width: 28%;
}

@media only screen and (max-width: 600px){
  .head{
    left: 25%;
    width: 55%;
  }
}