.container{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:15%;
    margin:10%;
}

.head{
    position: relative;
    left: 40%;
}

.footer{
    width:100%;
    position: absolute;
    top:275%;
}

@media only screen and (max-width: 600px){
    .footer{
        top: 425%;
    }
    .head{
        left: 10%;
    }
}